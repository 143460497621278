import React from "react";
import { MainModuleProvider } from "./main";
import { UserModuleProvider } from "./user";

const providersList: React.FunctionComponent<{}>[] = [
    MainModuleProvider,
    UserModuleProvider
]

const ModulesProviders: React.FunctionComponent = ({ children }) => {
    return providersList.reduce((Prev, Next) => {
        return (
            <Next>
                {Prev}
            </Next>
        )
    }, (<>{children}</>))
}

export default ModulesProviders;
